/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import '../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css'
import FileBrowser, {Icons} from 'react-keyed-file-browser';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import Detail from './Detail';

const url = 'https://37yyftofog.execute-api.us-west-2.amazonaws.com/prod/files';

function App(props) {
  const [files, setFiles] = useState([]);
  const [fileChanged, setFileChanged] = useState(false);
  const [detailFile, setDetailFile] = useState(null);
  const [searchMessage, setSearchMessage] = useState("Cargando...");

  useEffect(() => {
    loadFiles();
  }, []);

  function updateFile (file) {
    const index = files.findIndex(tempFile => tempFile.key === file.key);
    if (file && file.url) {
      setDetailFile(files[index]);
      window.location.href = '#content';
      return;
    }


    const body = {
      key: file.key
    };
    
    axios.post(url, body).then(response => {
      const { data } = response;
      files[index].url = data.data.url;
      setFiles(files);
      setFileChanged(!fileChanged);
      setDetailFile(files[index]);
      window.location.href = '#content';
    }, error => {
      console.log(error);
    });

  }

  function loadFiles () {
    axios.get(url).then(response => {
      const { data } = response;

      if (data.data.files.length <= 0) {
        return;
      }

      const newFiles = files.concat(data.data.files);

      const uniqueFiles = _.uniq(newFiles, 'key');

      setFiles(uniqueFiles);
      setSearchMessage("No se encontró ningún archivo para la búsqueda");
      
    }, error => {
      console.log(error);
    });
  }

  return (

    <div style={{
      backgroundColor: '#EFEFEF'
    }}>
      <h1 style={{
        textAlign: 'center',
        color: '#051272',
        paddingTop: '0.5em'
      }}>Repositorio TxP</h1>
      <div style={{
        width: '70%',
        margin: 'auto',
        paddingTop: '3em',
        color: '#051272'
      }}>
        <FileBrowser
          files={files}
          icons={Icons.FontAwesome(4)}
          detailRenderer={Detail}
          noFilesMessage={searchMessage}
          onPreviewOpen={updateFile}
          detailRendererProps={({
            actualFile: detailFile
          })}
        />
      </div>
    </div>

  );
}

export default App;
