import HomeSection from "./HomeSection";
import Aos from 'aos';
import { useEffect } from 'react';
import TwoColumn from "./TwoColumn";


function Home (props) {
    useEffect(() => {
        Aos.init({
            duration: 2000
        });
    }, []);

    return (
        <>
            <HomeSection id="bienvenida" fullheight={false} backgroundColor="#EFEFEF" textColor="#051272" text="El programa de Tutorías por Pares te da la bienvenida al repositorio TxP" withButton={true} transition="fade"/>
            <TwoColumn id="objetivo" title="Objetivo" fullheight={true} circleColor="#ddb01a" backgroundColor="#051272d8" textColor="#EFEFEF" text="El  objetivo principal es potenciar el desarrollo académico de los tutorados a través de los objetos de aprendizaje que los sitúan en escenarios reales para contribuir al desarrollo de habilidades intelectuales." transition="fade-left"/>
            <TwoColumn id="nosotros" title="Acerca de" fullheight={true} circleColor="#051272" backgroundColor="#EFEFEF" textColor="#051272" text="Tutorías por Pares hace uso de las TIC con el objetivo de impulsar el aprendizaje colaborativo por medio de los objetos de aprendizaje en diferentes formatos audiovisuales. Este entorno virtual sirve para apoyar a los estudiantes y docentes que requieran consultar los OA y se pueda aprovechar para la enseñanza, así como, en las actividades de autoestudio, es decir, que se propicie el conocimiento, desarrollo y práctica de los hábitos de estudios y plan de vida." transition="fade-right"/>
        </>
    );
}

export default Home;