import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './Home';
import { Router, Route } from 'react-router';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

ReactDOM.render(<Router history={history}>
  <Route path="/repositorio" component={App}></Route>
  <Route path="/" component={Home} exact={true}></Route>
</Router>,
  document.getElementById('file-container')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
