import './TwoColumn.css';


function TwoColumn (props) {
    return (
        <div data-aos={props.transition} id={props.id} style={{
            backgroundColor: props.backgroundColor,
            display: 'flex',
            justifyContent: 'space-evenly'
        }}>
            <div className="column">
                <div class="circle" style={{
                    backgroundColor: props.circleColor
                }}>{props.title}</div>
            </div>
            <div className="column">
                <p style={{
                    color: props.textColor,
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    justifyContent: 'center',
                    margin: 'auto',
                    fontSize: '2rem'
                }}>{props.text}</p>
            </div>
        </div>
    );
}

export default TwoColumn