/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React from 'react'
// import ReactDOM from 'react-dom'

class Detail extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
    close: PropTypes.func,
    actualFile: PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      extension: PropTypes.string,
      url: PropTypes.string,
    })
  }

  constructor () {
    super();

    this.state = {
      iFrameHeight: '0px'
    };
    this.myRef = React.createRef();
  }

  handleCloseClick = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.close()
  }

  downloadFile = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    console.log(name);

    fetch(url)
      .then(response => {
        console.log(response);
        return response.blob();
      })
      .then(blob => {
        console.log(blob);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        console.log(blobURL);
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(() => {});
  };


  render() {
    return (
      <div id="content" ref={this.myRef} style={{
        height: '100vh',
        width: '100%'
      }}>
        <a href="#" onClick={this.handleCloseClick}>Cerrar</a>
        {this.props.actualFile && this.props.actualFile.url ? 
          <><a style={{
            marginLeft: '3rem'
          }} href="#content" onClick={() => this.downloadFile(this.props.actualFile.url, this.props.actualFile.key)}>Descargar</a>
          <iframe
            title="Content"
            src={this.props.actualFile.url}
            style={{
              height: '90vh',
              width: '100%'
            }}
          >
          </iframe></> : ''
        }
      </div>
    )
  }
}

export default Detail