import './HomeSection.css';
import 'aos/dist/aos.css';

function HomeSection (props) {

    let navbarHeight = 0;

    if (!props.fullheight) {
        navbarHeight = document.getElementById('navbar').clientHeight;

    }

    const height = window.outerHeight - navbarHeight;
    let button = '';

    const onClick = () => {
        window.location.href='/repositorio'
    }

    if(props.withButton) {
        button = <button className='button' onClick={onClick}>Ir al repositorio</button>
    }

    return (
        <div id={props.id} data-aos={props.transition} className="home-section" style={{
            height: `${height}px`,
            backgroundColor: props.backgroundColor
        }}>
            <div className="home-section-child" style={{
                display: 'flex',
                width: '60%',
                margin: 'auto',
                height: `${height}px`,
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
                <p style={{
                    color: props.textColor
                }}>{props.text}</p>
                {button}
            </div>
        </div>
    )
}

export default HomeSection;